.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
    box-sizing: border-box;
}

.messages-container {
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 16px;
    max-height: calc(100vh - 150px); /* Ограничиваем высоту, чтобы скролл заработал */
}

.error-message {
    color: #888;
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
}


.round-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .round-button:hover {
    background-color: #0056b3;
  }
  
  .rce-input{
    overflow-y: hidden;
  }

  .ai-input-field{
    background-color: white;
    border-radius: 50px;
  }

  .rce-mbox.rce-mbox-right {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 0px !important;
  }

  .rce-mbox{
    border-radius: 16px !important;
    border-top-left-radius: 0 !important;
  }