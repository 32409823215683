/* App.css */
*{
  -ms-overflow-style: none;
}


::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: #f2f4f6; 
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 10px;
  margin-top: 20px;
  border-radius: 16px;
  background-color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.chat-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header-logo {
  margin: 20px;
  height: 50px;
}

.header-logo.ingos{
  margin-top: 26px;
}

@media (max-width: 767px){
  .header-logo {
    margin: 16px;
    height: 40px;
  }
}

@media (max-width: 479px){
  .header-logo {
    margin: 10px;
    height: 36px;
  }
  .header-logo.ingos{
    margin-top: 16px;
  }
}

@media (max-width: 413px){
  .header-logo {
    margin: 10px;
    height: 30px;
  }
  .header-logo.ingos{
    margin-top: 15px;
  }
}

.modal-x {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-x-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-x-content button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
}
